import React, { useState } from 'react'
import './Prix.css'
import Check from '../../components/ListeIcon/Check'
import NoCheck from '../../components/ListeIcon/NoCheck'
import PopUp from '../../components/PopUp/PopUp'

function Prix() {
    const [isView, setIsView] = useState(false)

    return (
        <div className='prix'>
            <h1 className='titre_prix'>Choisissez votre tarifaction idéal</h1>
            <div className='card_container'>
                <div className='card'>
                    <h1>Free</h1>
                    <p>La solution gratuite pour tout débutant sur l'application !</p>
                    <h2><strong>0,00</strong>€/par mois</h2>
                    <div className='button_container'><button onClick={() => setIsView(true)}>Selectionner</button></div>
                    <hr />
                    <ul>
                        <li><Check />1 seul groupe</li>
                        <li><Check />Jusqu'à 3 stockages</li>
                        <li><NoCheck />Pas d'amis dans les groupes</li>
                        <li><NoCheck />Quelques publicités</li>
                    </ul>
                </div>

                <div className='card choix'>
                    <h1>Starter</h1>
                    <p>La solution parfaite pour une utilisation régulière !</p>
                    <h2><strong>2,99</strong>€/par mois</h2>
                    <div className='button_container'><button onClick={() => setIsView(true)}>Selectionner</button></div>
                    <hr />
                    <ul>
                        <li><Check />Jusqu'à 3 groupes</li>
                        <li><Check />Jusqu'à 3 amis par groupe</li>
                        <li><Check />Aucune limite de stockage</li>
                        <li><Check />Pas de publicités</li>
                    </ul>

                    <div className='choix_container'>
                        Choix de l'equipe
                    </div>
                </div>

                <div className='card'>
                    <h1>Premium</h1>
                    <p>La solution pour une utilisation complète sans restriction !</p>
                    <h2><strong>4,99</strong>€/par mois</h2>
                    <div className='button_container'><button onClick={() => setIsView(true)}>Selectionner</button></div>
                    <hr />
                    <ul>
                        <li><Check />Aucune limite de groupe</li>
                        <li><Check />Autant d'amis que souhaitez par groupe</li>
                        <li><Check />Aucune limite de stockage</li>
                        <li><Check />Accès aux propositions de recettes personnalisées</li>
                        <li><Check />Pas de publicités</li>
                    </ul>
                </div>
            </div>

            <PopUp isView={isView} setIsView={setIsView} />
        </div>
    )
}

export default Prix